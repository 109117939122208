import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";

import "./App.css";

import "swiper/css/pagination";
import "swiper/css/navigation";
import Home from "./Home";
import NavBar from "./NavBar";
import NotFound from "./NotFound";
import "animate.css";
import About from "./About";
import BusinessAdministration from "./services/BusinessAdministration";
import CreativeServices from "./services/CreativeServices";
import CustomerSupport from "./services/CustomerSupport";
import Marketing from "./services/Marketing";
import SalesSolutions from "./services/SalesSolutions";
import SoftwareDevelopment from "./services/SoftwareDevelopment";
import ScheduleCall from "./ScheduleCall";
import Terms from "./Terms";
import Policy from "./Policy";
import ScrollToHash from "./ScrollToHash";
import ExecutiveAssistance from "./services/ServicesDetails/ExecutiveAssistance";
import CustomerSuccesManagement from "./services/ServicesDetails/CustomerSuccesManagement";
import QualityControl from "./services/ServicesDetails/QualityControl";
import TrainingDevelopment from "./services/ServicesDetails/TrainingDevelopment";
import OperationsManagement from "./services/ServicesDetails/OperationsManagement";
import PayrollManagement from "./services/ServicesDetails/PayrollManagement";
import TrainingDevelopment2 from "./services/ServicesDetails/TrainingDevelopment2";
import CustomerSupportRepresentatives from "./services/ServicesDetails/CustomerSupportRepresentatives";
import TeamManagement from "./services/ServicesDetails/TeamManagement";
import CustomerSuccesManagement2 from "./services/ServicesDetails/CustomerSuccesManagement2";
import QualityControl2 from "./services/ServicesDetails/QualityControl2";
import RealTimeAnalysts from "./services/ServicesDetails/Real-TimeAnalysts(RTA)";
import DataManagement from "./services/ServicesDetails/DataManagement";
import OutboundSales from "./services/ServicesDetails/OutboundSales";
import StrategyBuilding from "./services/ServicesDetails/StrategyBuilding";
import BrandMarketing from "./services/ServicesDetails/BrandMarketing";
import ContentCreation from "./services/ServicesDetails/ContentCreation";
import SocialMediaMarketing from "./services/ServicesDetails/SocialMediaMarketing";
import SMSMarketing from "./services/ServicesDetails/SMSMarketing";
import MotionGraphics from "./services/ServicesDetails/MotionGraphics";
import EditingVideos from "./services/ServicesDetails/EditingVideos";
import VisualIdentity from "./services/ServicesDetails/VisualIdentity";
import PublicationsAdvertisements from "./services/ServicesDetails/PublicationsAdvertisements";
import AcquisitionClosers from "./services/ServicesDetails/AcquisitionClosers";
import EmailMarketing from "./services/ServicesDetails/EmailMarketing";
import NewsLetter from "./NewsLetter";
import MessageNewsLetter from "./MessageNewsLetter";

function App() {
  return (
    <BrowserRouter>
      <>
        <NavBar />

        <ScrollToHash />

        <Routes>
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/ScheduleCall" element={<ScheduleCall />} />

          <Route
            path="/BusinessAdministration"
            element={<BusinessAdministration />}
          />
          <Route path="/CreativeServices" element={<CreativeServices />} />
          <Route path="/CustomerSupport" element={<CustomerSupport />} />
          <Route path="/Marketing" element={<Marketing />} />
          <Route path="/SalesSolutions" element={<SalesSolutions />} />
          <Route
            path="/SoftwareDevelopment"
            element={<SoftwareDevelopment />}
          />

          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />

          <Route
            path="/ExecutiveAssistance"
            element={<ExecutiveAssistance />}
          />
          <Route
            path="/CustomerSuccesManagement"
            element={<CustomerSuccesManagement />}
          />
          <Route path="/QualityControl" element={<QualityControl />} />
          <Route
            path="/TrainingDevelopment"
            element={<TrainingDevelopment />}
          />
          <Route path="/PayrollManagement" element={<PayrollManagement />} />
          <Route
            path="/OperationsManagement"
            element={<OperationsManagement />}
          />

          <Route
            path="/CustomerSupportRepresentatives"
            element={<CustomerSupportRepresentatives />}
          />
          <Route path="/TeamManagement" element={<TeamManagement />} />
          <Route
            path="/TrainingDevelopmentS"
            element={<TrainingDevelopment2 />}
          />
          <Route
            path="/CustomerSuccesManagementS"
            element={<CustomerSuccesManagement2 />}
          />
          <Route path="/QualityControlS" element={<QualityControl2 />} />
          <Route path="/RealTimeAnalysts" element={<RealTimeAnalysts />} />

          <Route path="/OutboundSales" element={<OutboundSales />} />
          <Route path="/DataManagement" element={<DataManagement />} />
          <Route path="/AcquisitionClosers" element={<AcquisitionClosers />} />

          <Route path="/StrategyBuilding" element={<StrategyBuilding />} />
          <Route path="/BrandMarketing" element={<BrandMarketing />} />
          <Route path="/ContentCreation" element={<ContentCreation />} />
          <Route
            path="/SocialMediaMarketing"
            element={<SocialMediaMarketing />}
          />
          <Route path="/SMSMarketing" element={<SMSMarketing />} />
          <Route path="/EmailMarketing" element={<EmailMarketing />} />

          <Route
            path="/PublicationsAdvertisements"
            element={<PublicationsAdvertisements />}
          />
          <Route path="/VisualIdentity" element={<VisualIdentity />} />
          <Route path="/EditingVideos" element={<EditingVideos />} />
          <Route path="/MotionGraphics" element={<MotionGraphics />} />
          <Route
            path="/SoftwareDevelopment"
            element={<SoftwareDevelopment />}
          />
          <Route path="/login" element={<NewsLetter />} />
          <Route path="/MessageNewsLetter" element={<MessageNewsLetter />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
